$sizes: (
  0: 0px,
  4: 4px,
  8: 8px,
  16: 16px,
  24: 24px,
  32: 32px,
  40: 40px,
  56: 56px,
  72: 72px,
  144: 144px,
);

@mixin size-modifiers($attributes) {
  @each $name, $size in $sizes {
    &#{$name} {
      @each $attribute in $attributes {
        #{$attribute}: $size;
      }
    }
  }
}

.p {
  @include size-modifiers('padding');
}

.pt {
  @include size-modifiers('padding-top');
}

.pb {
  @include size-modifiers('padding-bottom');
}

.pl {
  @include size-modifiers('padding-left');
}

.pr {
  @include size-modifiers('padding-right');
}

.ph {
  @include size-modifiers('padding-left' 'padding-right');
}

.pv {
  @include size-modifiers('padding-top' 'padding-bottom');
}

.m {
  @include size-modifiers('margin');
}

.mt {
  @include size-modifiers('margin-top');
}

.mb {
  @include size-modifiers('margin-bottom');
}

.ml {
  @include size-modifiers('margin-left');
}

.mr {
  @include size-modifiers('margin-right');
}

.mh {
  @include size-modifiers('margin-left' 'margin-right');
}

.mv {
  @include size-modifiers('margin-top' 'margin-bottom');
}
