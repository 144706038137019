@import '../../../../styles/_spacing';

.backIcon {
  svg {
    display: block;
  }
}

.layoutHeader {
  @extend .pb32;
}

.layoutTitleSkeleton {
  width: 250px;
  height: 32px;
}

.topRightElements {
  @extend .mb8;
}
