@import '../../../styles/__colors';
@import '../../../styles/__mixins';
@import '../../../styles/__fonts';
@import '../../../styles/__spacing';

$font-size-error-text-sm: 1.25rem;

.errorImage {
  @extend .mv56;
  object-fit: contain;
  width: calc(100vw - 240px);
  margin-left: -40px !important;
}

.errorText {
  @extend .mh32;
  font-size: $font-size-error-text-sm;
  color: $red-dark;
  font-weight: $font-weight-light;
  font-family: $font-open-sans-family;
}

.errorContainer {
  @extend .pt144;
}

.errorContainerNoMargin {
  @extend .mt72;
}

.errorMessage {
  @extend .ph32, .pv16;
  background: $white;
}

.full-width {
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
}
