@import '../../../../styles/_colors';
@import '../../../../styles/_fonts';
@import '../../../../styles/_spacing';
.contentContainer {
    height: 100%;
}

.atriasLogo {
    @extend .mt32, .mb16;
    width: 80px;
    font-size: 64px !important;
}


.links {
    width: 100%;
    margin-top: 40px !important;
    .navLabel {
    @extend .pv4, .pl24, .mb4;
    width: auto;
    display: block;

    color: $black;
    cursor: pointer;
    font-weight: $font-weight-bold;
    overflow: auto;
    text-decoration: none;
    white-space: nowrap;
    font-size: 1.375rem;
    font-family: $font-open-sans-family;

    &:hover,
    &:focus {
      color: $red-dark;
      outline: none;
    }
    }

    .activeNavLabel {
    @extend .navLabel;
    color: $red-dark;
    border-right: 4px solid $red-dark;
  }

 
} 

.bottomItems {
  @extend .ph24, .pv16;
  width: 100%;
  box-sizing: border-box;
}