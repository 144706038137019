@import '../../../styles/_spacing';
@import '../../../styles/_colors';
$drawerWidth: 240px;

.navDrawer > div {
    width: $drawerWidth;
    align-items: center;
    border: none;
}

.content {
    margin-left: $drawerWidth;
    @extend .ph40, .pt56, .pb8;
    background-color: $grey-pale;
    flex: 1;

}

.envHeader {
    margin-left: $drawerWidth;
}