$red-pale: #f2dada;
$red-light: #ce3135;
$red: #af1f22;
$red-dark: #991425;

$grey-light: #e4eaf2;
$grey: #d3d3d3;
$grey-dark: #979797;
$grey-darkest: #686868;
$grey-pale: #f5f5f5f5;

$black: #000;
$white: #fff;